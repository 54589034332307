<template>
  <v-container class="fill-height">
    <!-- <WidgetAlert /> -->
    <v-row justify="center">
      <v-col cols="12" class="mb-md-2 pt-md-2" align-self="start">
        <h2 class="header font-weight-bold my-2 white--text text-center">
          NOTIFICACIONS PER SUBSCRIPCIÓ
        </h2>

        <v-spacer></v-spacer>

        <p class="subheading font-weight-regular mb-3 white--text text-center">
          Aquí pots veure i gestionar les teves subscripcions a notificacions
          sobre les parades dels serveis que tu vulguis.
        </p>
      </v-col>
    </v-row>

    <v-alert type="error" v-if="showError" class="my-2 mx-auto">
      {{ alertMsg }}
    </v-alert>
    <v-alert type="success" v-if="showSuccess" class="my-2 mx-auto">
      Les teves dades s'han actualitzat correctament!
    </v-alert>

    <v-row justify="center">
      <v-col cols="12" md="6">
        <v-card dense class="px-md-2" id="subscriptionsList" tabindex="-1">
          <v-card-title dense>
            <h4 class="title primary--text">Les meves subscripcions</h4>
          </v-card-title>
          <v-list dense v-if="subscriptions.length >= 1" v-cloak>
            <v-list-item-group v-model="subscriptions" active-class="">
              <v-list-item
                v-for="(item, index) in subscriptions"
                :key="index"
                inactive
              >
                <v-list-item-content>
                  <v-list-item-title>
                    <v-chip
                      label
                      outlined
                      class="mr-1"
                      :ripple="false"
                      :input-value="true"
                    >
                      Parada: {{ item.stop }}
                    </v-chip>
                    <span>
                      {{ item.address }}
                    </span>
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn icon @click.stop="removeSubscription(item)">
                    <v-icon small>
                      fas fa-trash
                    </v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list-item-group>
          </v-list>

          <v-alert v-else small dense type="info" class="mb-3 mx-2">
            No estàs subscrit a notificacions de cap servei.
          </v-alert>

          <v-card-actions>
            <v-row dense>
              <v-col cols="auto">
                <v-btn
                  :disabled="subscriptionLines.length > 0"
                  @click="getSubscriptionLines()"
                  color="primary"
                >
                  Mostrar Línies
                </v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn @click="cancelar()" color="warning">
                  Tornar a Espai Client
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-row justify="center" v-if="subscriptionLines.length > 0">
      <v-col cols="12" md="5">
        <!-- Lines List -->
        <v-card class="px-md-2 pb-1 mt-2">
          <v-card-title>
            <v-progress-linear
              v-if="loadingLines"
              indeterminate
              color="orange"
            ></v-progress-linear>
            <h4 class="title primary--text">
              Línies Disponibles
            </h4>
          </v-card-title>
          <v-card-subtitle>
            Selecciona una línia per a veure les parades.
          </v-card-subtitle>
          <v-list three-line v-if="subscriptionLines.length > 0">
            <v-list-item-group v-cloak v-model="selectedLine" active-class="">
              <v-list-item
                v-for="(item, index) in subscriptionLines"
                :key="index"
                @click="showSubscriptionLineStops(item.line)"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    <v-chip
                      label
                      color="grey darken-3"
                      text-color="white"
                      class="mr-1"
                    >
                      Línia: {{ item.line }}
                    </v-chip>
                    <span label outlined class="body-1 mr-2">
                      {{ item.name }}
                    </span>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <span class="font-weight-bold caption">ORÍGEN:</span
                    >{{ item.source }} |
                    <span class="font-weight-bold caption">DESTÍ:</span
                    >{{ item.destination }} |
                    <span class="font-weight-bold caption">SENTIT:</span
                    >{{ lineSense(item.sense) }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon>fas fa-angle-right</v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-list-item-group>
          </v-list>
          <v-alert v-else dense type="info" class="mb-3 text-left mx-2">
            No hi han línies disponibles.
          </v-alert>
        </v-card>
        <a id="stopslist"></a>
      </v-col>
      <v-col cols="12" md="5" v-show="showStopsList">
        <!-- Stops List -->
        <v-card class="px-md-2 py-1 mt-2">
          <v-progress-linear
            v-if="loadingStops"
            indeterminate
            color="orange"
          ></v-progress-linear>
          <v-card-title>
            <h4 class="title primary--text">
              Parades Disponibles
            </h4>
            <a name="stops-list"></a>
            <v-chip label color="grey darken-3" text-color="white" class="mx-2">
              Línia: {{ selectedLineId }}
            </v-chip>
          </v-card-title>
          <v-card-subtitle>
            Selecciona una o més parades per a rebre notificacions i avisos
            servei en aquesta parada.
          </v-card-subtitle>
          <v-alert
            v-if="subscriptionLineStops.length < 1 && !loadingStops"
            dense
            type="info"
            class="mb-3 text-left mx-2"
          >
            No hi han parades disponibles.
          </v-alert>
          <!-- Stops List -->
          <div v-else>
            <v-list three-line v-cloak>
              <v-list-item-group v-model="selectedStops" active-class="">
                <v-list-item
                  v-for="(item, index) in showStopsByLine"
                  :key="index"
                  :value="item"
                  :disabled="disabledByExist(item.id)"
                  link
                  @click.stop="updateList(item)"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-chip
                        label
                        outlined
                        :ripple="false"
                        :input-value="true"
                        color="grey darken-3"
                        text-color="grey darken-4"
                        class="mr-1"
                      >
                        Parada: {{ item.stop }}
                      </v-chip>
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <span class="body-2">{{ item.address }}</span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action v-if="disabledByExist(item.id) === false">
                    <v-icon small flat>fas fa-plus</v-icon>
                  </v-list-item-action>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" align-self="end">
        <v-img
          :src="require('../assets/img/logo-autocars-del-penedes-petit.png')"
          class="mt-5"
          contain
          height="80"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Subscriptions from "@/api/subscriptions.js";
// import WidgetAlert from "@/components/WidgetAlert";

export default {
  name: "user-subscriptions",

  data: () => ({
    token: null,
    loadingLines: false,
    loadingStops: false,
    itemsLoading: false,
    userInfo: null,
    showError: false,
    showSuccess: false,
    alertMsg: "",
    valid: false,
    userDataForm: {
      name: "",
      surnames: "",
      phone: "",
      email: ""
    },
    editable: false,
    rules: {
      required: value => !!value || "Requerit"
      // min: v => (v && v.length >= 8) || "Min 8 caràcters"
    },
    subscriptionLines: [],
    subscriptionLineStops: [],
    subscriptions: [],
    selectedLine: null,
    selectedLineId: null,
    selectedStops: [],
    // selectedStopsIds: [],
    showStopsList: false
  }),

  // components: {
  //   WidgetAlert
  // },

  created() {
    this.userInfo = this.$store.getters.getUserInfo;
    this.token = this.$store.state.token;
  },

  mounted() {
    this.userDataForm.name = this.userInfo.name;
    this.userDataForm.surnames = this.userInfo.surnames;
    this.userDataForm.phone = this.userInfo.phone;
    this.userDataForm.email = this.userInfo.email;
    this.subscriptions = this.$store.getters.getSubscriptions;
  },

  updated() {
    this.subscriptions = this.$store.getters.getSubscriptions;
  },

  computed: {
    showStopsByLine() {
      return this.subscriptionLineStops;
    },
    userSubscriptions() {
      return this.$store.getters.getSubscriptions;
    },
    subscriptionsList() {
      return this.subscriptions;
    }
  },

  methods: {
    // ...mapActions([]),
    validateUserForm() {
      this.$refs.userDataForm.validate();
    },

    reset() {
      this.$refs.userDataForm.reset();
      this.$router.push({ path: "/" }).catch(() => {});
    },

    cancelar() {
      this.$router.push({ path: "/profile" }).catch(() => {});
    },

    errorAlert() {
      this.showError = true;
      var v = this;
      setTimeout(function() {
        v.showError = false;
      }, 4000);
      this.loadingLines = false;
      this.loadingStops = false;
    },

    successAlert() {
      this.showSuccess = true;
      var v = this;
      setTimeout(function() {
        v.showSuccess = false;
        // v.$router.push({ path: "/profile" }).catch(() => {});
      }, 3000);
    },

    getSubscriptionLines() {
      this.loadingLines = true;
      const userData = {
        token: this.token,
        user_id: this.userInfo.userId
      };
      this.$store
        .dispatch("setSubscriptionLines", userData)
        .then(response => {
          console.log("SubscriptionLines: " + response.data.length);
          this.subscriptionLines = response.data;
          this.loadingLines = false;
          // this.focusOnLinesList();
        })
        .catch(error => {
          this.alertMsg = "Oops! No ha estat possible obtenir les línies.";
          this.errorAlert();
          console.log(error);
        });
    },

    lineSense(sense) {
      if (sense === 1) {
        return "Tarragona";
      } else if (sense === 2) {
        return "Barcelona";
      } else {
        return "";
      }
    },

    async getSubscriptionLineStops(line) {
      let reqData = {
        token: this.token,
        user_id: this.userInfo.userId,
        line_id: line
      };
      this.loadingStops = true;
      this.selectedStops = [];
      try {
        let respData = await Subscriptions.getSubscriptionStops(reqData);
        if (respData.data) {
          this.subscriptionLineStops = respData.data;
          this.showError = false;
        } else {
          this.alertMsg = "Sense parades disponibles.";
          this.errorAlert();
        }
        this.loadingStops = false;
        this.showStopsList = true;
        if (this.$vuetify.breakpoint.mobile) {
          this.$vuetify.goTo("#stopslist");
        }
      } catch (error) {
        this.alertMsg =
          "Oops! Hi ha hagut un problema amb la obtenció de les parades.";
        this.errorAlert();
        console.log(error);
        this.loadingStops = false;
      }
    },

    showSubscriptionLineStops(line) {
      this.selectedStops = [];
      this.selectedLineId = line;
      if (this.selectedLineId) {
        this.getSubscriptionLineStops(line);
      }
    },

    async addSubscriptions() {
      let a = this.subscriptions;
      let stops = a.map(a => a.id);
      let stopsString = JSON.stringify(stops);
      let reqData = {
        token: this.token,
        user_id: this.userInfo.userId,
        stops: stopsString
      };
      try {
        let respData = await this.$store.dispatch("addSubscriptions", reqData);
        let subscriptionsStore = this.subscriptions;
        // console.log("subscriptions: " + JSON.stringify(a));
        if (respData.data) {
          this.$store.commit("addSubscriptions", subscriptionsStore);
          this.alertMsg =
            "Les teves subscripcions s'han actualitzat correctament en el sistema.";
          this.showError = false;
          this.successAlert();
        } else {
          this.alertMsg =
            "Oops! No s'han pogut actualitzar les susbcripcions correctament en el sistema.";
          this.errorAlert();
        }
      } catch (error) {
        this.alertMsg =
          "Oops! Hi ha hagut un problema de comunicació amb el servei.";
        this.errorAlert();
        console.log(error);
      }
    },

    updateList(item) {
      const subscriptions = this.subscriptions;
      function getItemId(item) {
        if (subscriptions.find(e => e.id === item.id) !== undefined || null) {
          return true;
        } else {
          return false;
        }
      }
      if (getItemId(item) === false) {
        this.subscriptions.push(item);
        this.selectedStops = [];
        this.addSubscriptions();
      } else {
        console.log("This item exist in list");
        this.selectedStops = [];
        return;
      }
      if (this.$vuetify.breakpoint.mobile) {
        this.$vuetify.goTo("#subscriptionsList");
      }
    },

    disabledByExist(id) {
      // let id = item.id;
      const subscriptions = this.subscriptions;
      const subscriptionsIds = subscriptions.map(
        subscriptions => subscriptions.id
      );
      if (subscriptionsIds.includes(id)) {
        // console.log("included: " + id);
        return true;
      } else {
        return false;
      }
    },

    async removeSubscription(item) {
      let itemIndex = this.subscriptions.indexOf(item);
      console.log(itemIndex);
      let reqData = {
        token: this.token,
        user_id: this.userInfo.userId,
        stop_id: item.id
      };
      try {
        let isServerDeleted = await this.$store.dispatch(
          "removeSubscription",
          reqData
        );
        if (isServerDeleted) {
          // let itemDeleted = this.subscriptions.splice(itemIndex, 1);
          console.log("items deleted from server ");
          this.subscriptions = this.$store.getters.getSubscriptions;
          this.selectedStops = [];
          this.alertMsg = "La subscripció s'ha eliminat correctament.";
          this.successAlert();
        } else {
          // this.subscriptions.push(item);
          this.alertMsg =
            "Oops! No s'ha pogut eliminar la teva subscripció. Prova-ho més tard.";
          this.errorAlert();
        }
      } catch (error) {
        this.alertMsg =
          "Oops! Hi ha hagut un problema de comunicació amb el servei.";
        this.errorAlert();
        console.log(error);
      }
    }

    // async submitUpdateUserData() {
    //   let formData = this.userDataForm;
    //   let userData = {
    //     name: formData.name,
    //     surnames: formData.surnames,
    //     token: this.token
    //   };
    //   this.loading = true;
    //   try {
    //     let respData = await this.userUpdate(userData);
    //     if (respData.data[0] === "user") {
    //       this.userInfo = respData.data[1];
    //       this.showError = false;
    //       this.successAlert();
    //     } else {
    //       this.errorAlert();
    //     }
    //     this.loading = false;
    //   } catch (error) {
    //     this.errorAlert();
    //   }
    // },

    // goLogin() {
    //   this.$router.push({ path: "/login-register" }).catch(() => {});
    // }
  }
};
</script>
